import { provideStoreDevtools } from '@ngrx/store-devtools';
import dayjs from 'dayjs';
export const environment = {
  apiBaseUrl: 'https://dashboard-herobot.cgmotors.com.np',
  port: ':443',
  host:'https://dashboard-herobot.cgmotors.com.np',
  version: '/rest/v1/',
  isDev: false,
  googleAnalyticsUrl:
    'https://googleanalytics.palmchatbot.com/rest/v1/analytics',
  title: 'Dashboard',
  copyRightName:'Palmmind Technology',
  startDate: dayjs().subtract(3, 'month').toISOString(),
  secret: '12345678901234567890123456789012',
  copyright: {
    name: 'Palmmind Technology',
    logo: '/assets/brand/elex4.png',
    url: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      website: '',
    },
    },
  production:true,
  providers: [],
cacheTime: 0, //value treat as second
};
